.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_header {
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 10;
}

.app_main{
  padding-top: 60px;
}

.app__bg {
  background-size: cover;
  background: linear-gradient(17.54deg, #313030 -12.84%, #000000 35.12%, #272727 107.71%, #0D0D0D 107.71%) repeat fixed center;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.p__alegreya_h4,
.p__alegreya_h3,
.p__alegreya_h2,
.p__alegreya_h1 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.p__opensans_s1 {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

@media screen and (min-width: 1500px) {
  .p__alegreya_h1 {
    font-size: 55px;
  }

  .p__alegreya_h2 {
    font-size: 37px;
  }

  .p__alegreya_h3 {
    font-size: 24px;
  }

  .p__alegreya_h4 {
    font-size: 20px;
  }

  .p__opensans_s1 {
    font-size: 18px;
  }

  .section__padding {
    padding: 4rem 20rem;
  }
}

@media screen and (max-width: 1500px) {
  .p__alegreya_h1 {
    font-size: 55px;
  }

  .p__alegreya_h2 {
    font-size: 37px;
  }

  .p__alegreya_h3 {
    font-size: 24px;
  }

  .p__alegreya_h4 {
    font-size: 20px;
  }

  .p__opensans_s1 {
    font-size: 18px;
  }

  .section__padding {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .p__alegreya_h1 {
    font-size: 50px;
  }

  .p__alegreya_h2 {
    font-size: 32px;
  }

  .p__alegreya_h3 {
    font-size: 22px;
  }

  .p__alegreya_h4 {
    font-size: 20px;
  }

  .p__opensans_s1 {
    font-size: 15px;
  }

}

@media screen and (max-width: 450px) {
  .p__alegreya_h1 {
    font-size: 45px;
  }

  .p__alegreya_h2 {
    font-size: 27px;
  }

  .p__alegreya_h3 {
    font-size: 17px;
  }

  .p__alegreya_h4 {
    font-size: 15px;
  }

  .p__opensans_s1 {
    font-size: 12px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}
