.app__specialitem {
    height: 100%;
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
}

.app__specialitem-sub{
    flex: 1;
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-left: 25px;
    align-items: center;
    justify-content: center;
}

.app__specialitem-image {
    flex: 1;
    height: 100%;
    width: 50%;
    display: block;
}

@media screen and (min-width: 1150px) {
    .app__specialitem {
        text-align:right;
    }
}

@media screen and (max-width: 1150px) {
    .app__specialitem {
        flex-direction: column;
        align-items: center;
        text-align:center;
        width: 100%;
    }

    .app__specialitem-sub{
        flex: 1;
        height: 25%;
        width: 100%;
        display: flex;
        justify-content: left;
        flex-direction: column;
        text-align: center;
        padding-left: 0;
    }

    .app__specialitem-image {
        flex: 1;
        height: 75%;
        width: 100%;
        display: block;
    }
}

