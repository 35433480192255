.app__menu {
    flex-direction: column;
}

.app__menu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__menu-menu {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__menu-menu_cake,
.app__menu-menu_drinks {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__menu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}

.app__menu_menu_divider {
    position: relative;
    min-width:50px;
    max-width:50px;
    display:inline-block;
    margin:0 0.25%;
}

@media screen and (max-width: 1150px) {
    .app__menu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}
