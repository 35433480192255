@import url(https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);
:root {
  --font-base: 'Alegreya', serif;
  --font-alt: 'Open Sans', sans-serif;
  
  --color-golden: #DCCA87;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: right;
}

.app__aboutus-content_knife {
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 2rem 0;
    color: var(--color-grey);
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}
.app__contactus {
    width: 100%;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 0;
}

.app__contactus-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 5rem;
    padding: 0 2rem;
}

.app__contactus-links_map,
.app__contactus-links_logo,
.app__contactus-links_hours {
    flex: 1 1;
    margin: 1rem;
    text-align: center;
}

.app__contactus-links_logo img:first-child {
    width: 210px;
    margin-bottom: 0.75rem;
}


.app__contactus-links_hours p:nth-child(2n + 1) {
    margin-bottom: 1rem;
}

.app__contactus-links_icons {
    margin-top: 0.5rem;
}

.app__contactus-links_icons svg {
    color: var(--color-white);
    margin: 0.5rem;
    font-size: 24px;
    cursor: pointer;
}

.app__contactus-links_icons svg:hover {
    color: var(--color-golden);
}

.app__contactus-headtext {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 32px;
    line-height: 41.6px;
    margin-bottom: 1rem;
}

.contactus__copyright {
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__contactus-headtext {
        font-size: 51px;
        line-height: 61.6px;
    }
}

@media screen and (max-width: 1150px) {
    .app__contactus-links {
        align-items: center;
        flex-direction: column;
        padding: 0;
    }

    .app__contactus-links_map,
    .app__contactus-links_logo,
    .app__contactus-links_hours {
        margin: 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__contactus {
        padding: 0 0 2rem 0;
    }
}

@media screen and (max-width: 350px) {
    .app__contactus-links_logo img:first-child {
        width: 80%;
    }
}

.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    flex-direction: column;
    position: absolute;
}

.app__footerOverlay-black {
    height: 25%;
    background: var(--color-black);
}

.app__footerOverlay-img {
    height: 75%;
}

.app__menuitem {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
}

.app__menuitem-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__menuitem-sub {
    width: 100%;
    margin-top: 0.2rem;
}

.app__menuitem-name {
    flex: 1 1;
}

.app__menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 0 1rem;
}

.app__menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(17.54deg, #313030 -12.84%, #000000 35.12%, #272727 107.71%, #0D0D0D 107.71%) repeat fixed center;
    border-bottom: 4px solid #DCCA87;
    padding: 1rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 250px;
}

.app__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    margin: auto;

    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links li:hover {
    color: var(--color-grey);
}

.app__navbar-order {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-order a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}

.app__navbar-order a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-order div {
    width: 1px;
    height: 30px;
    background: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: .5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-white);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 1150px) {
    .app__navbar-logo img {
        width: 210px;
    }
    .app__navbar-order {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-order {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-order {
        display: none;
    }

    .app__navbar-logo img {
        width: 180px;
    }
}
.app__specialitem {
    height: 100%;
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
}

.app__specialitem-sub{
    flex: 1 1;
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-left: 25px;
    align-items: center;
    justify-content: center;
}

.app__specialitem-image {
    flex: 1 1;
    height: 100%;
    width: 50%;
    display: block;
}

@media screen and (min-width: 1150px) {
    .app__specialitem {
        text-align:right;
    }
}

@media screen and (max-width: 1150px) {
    .app__specialitem {
        flex-direction: column;
        align-items: center;
        text-align:center;
        width: 100%;
    }

    .app__specialitem-sub{
        flex: 1 1;
        height: 25%;
        width: 100%;
        display: flex;
        justify-content: left;
        flex-direction: column;
        text-align: center;
        padding-left: 0;
    }

    .app__specialitem-image {
        flex: 1 1;
        height: 75%;
        width: 100%;
        display: block;
    }
}


.app__menu {
    flex-direction: column;
}

.app__menu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__menu-menu {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__menu-menu_cake,
.app__menu-menu_drinks {
    flex: 1 1;
    width: 100%;
    flex-direction: column;
}

.app__menu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}

.app__menu_menu_divider {
    position: relative;
    min-width:50px;
    max-width:50px;
    display:inline-block;
    margin:0 0.25%;
}

@media screen and (max-width: 1150px) {
    .app__menu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.app__specials {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.app__specials-swiper {
    width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.app__specials-swiper_slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__specials-swiper_slide_content {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 750px;
    max-width: 1000px;

}
.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_header {
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 10;
}

.app_main{
  padding-top: 60px;
}

.app__bg {
  background-size: cover;
  background: linear-gradient(17.54deg, #313030 -12.84%, #000000 35.12%, #272727 107.71%, #0D0D0D 107.71%) repeat fixed center;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.p__alegreya_h4,
.p__alegreya_h3,
.p__alegreya_h2,
.p__alegreya_h1 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
          font-feature-settings: 'tnum' on, 'lnum' on;
}

.p__opensans_s1 {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

@media screen and (min-width: 1500px) {
  .p__alegreya_h1 {
    font-size: 55px;
  }

  .p__alegreya_h2 {
    font-size: 37px;
  }

  .p__alegreya_h3 {
    font-size: 24px;
  }

  .p__alegreya_h4 {
    font-size: 20px;
  }

  .p__opensans_s1 {
    font-size: 18px;
  }

  .section__padding {
    padding: 4rem 20rem;
  }
}

@media screen and (max-width: 1500px) {
  .p__alegreya_h1 {
    font-size: 55px;
  }

  .p__alegreya_h2 {
    font-size: 37px;
  }

  .p__alegreya_h3 {
    font-size: 24px;
  }

  .p__alegreya_h4 {
    font-size: 20px;
  }

  .p__opensans_s1 {
    font-size: 18px;
  }

  .section__padding {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .p__alegreya_h1 {
    font-size: 50px;
  }

  .p__alegreya_h2 {
    font-size: 32px;
  }

  .p__alegreya_h3 {
    font-size: 22px;
  }

  .p__alegreya_h4 {
    font-size: 20px;
  }

  .p__opensans_s1 {
    font-size: 15px;
  }

}

@media screen and (max-width: 450px) {
  .p__alegreya_h1 {
    font-size: 45px;
  }

  .p__alegreya_h2 {
    font-size: 27px;
  }

  .p__alegreya_h3 {
    font-size: 17px;
  }

  .p__alegreya_h4 {
    font-size: 15px;
  }

  .p__opensans_s1 {
    font-size: 12px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}

