.app__specials {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.app__specials-swiper {
    width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.app__specials-swiper_slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.app__specials-swiper_slide_content {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 750px;
    max-width: 1000px;

}